import { ReactNode } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import classNames from 'classnames';
import cleeAILogo from 'public/images/cleeai-logo-white.svg?url';
import googleLogo from 'public/images/icons/Google__G__logo.svg?url';
import Spinner from 'components/Spinner';

import fatArrowForwardWhiteIcon from 'public/images/icons/fatArrowForwardWhite.svg?url';
import chevronLeftIconBlue from 'public/images/icons/chevronLeftBlue.svg?url';

import style from './style.module.css';
import Button from 'components/Button';

interface Props {
  children: ReactNode;
}
interface AuthFormSubHeadingProps extends Props {
  className?: string;
}
interface AuthFormTitleProps extends Props {
  className?: string;
}

interface AuthFormHelpLinkProps extends Props {
  text: string;
  className?: string;
}

interface AuthFormProps extends Props {
  isLoading?: boolean;
  className?: string;
}

interface AuthFormSubmitButtonProps extends Props {
  className?: string;
  onClickCB?: () => void;
}

interface AuthFormBackLinkProps {
  onClickHandler?: React.MouseEventHandler<HTMLAnchorElement>;
  className?: string;
}

export function AuthFormBackLink({
  onClickHandler,
  className,
}: AuthFormBackLinkProps) {
  return (
    <Link
      href="/login"
      className={classNames(style.backLink, className)}
      onClick={onClickHandler}
    >
      <Image
        src={chevronLeftIconBlue}
        alt="Left chevron icon"
        height="24"
        width="24"
      />
      Back to login
    </Link>
  );
}

export function AuthFormSubHeading({
  children,
  className,
}: AuthFormSubHeadingProps) {
  return <p className={classNames(style.subHeading, className)}>{children}</p>;
}

export function AuthFormHelpLink({
  children,
  text,
  className,
}: AuthFormHelpLinkProps) {
  return (
    <div className={classNames(style.help, className)}>
      <div className={style.line} />
      <div className={style.helpText}>{text}</div>
      {children}
      <div className={style.line} />
    </div>
  );
}

export function AuthFormTitle({ children, className }: AuthFormTitleProps) {
  return <h1 className={classNames(style.title, className)}>{children}</h1>;
}

export function AuthFormGoogleButton({
  onClickCB,
  className,
  children,
}: AuthFormSubmitButtonProps) {
  return (
    <Button
      type="submit"
      isFullWidth={true}
      className={classNames(style.submitBtn, style.oauthBtn, className)}
      onClick={onClickCB}
    >
      <Image src={googleLogo} alt="Forward arrow icon" width={20} height={20} />
      {children}
    </Button>
  );
}

export function AuthFormSubmitButton({
  onClickCB,
  className,
  children,
}: AuthFormSubmitButtonProps) {
  return (
    <Button
      type="submit"
      isFullWidth={true}
      className={classNames(style.submitBtn, className)}
      onClick={onClickCB}
    >
      <Image
        src={fatArrowForwardWhiteIcon}
        alt="Forward arrow icon"
        width={18}
      />
      {children}
    </Button>
  );
}

export default function AuthForm({
  children,
  isLoading = false,
  className,
}: AuthFormProps) {
  return (
    <div className={classNames(style.page, className)}>
      {isLoading && <Spinner fullScreen={true} />}
      <div className={style.left}>
        <div className={style.logoContainer}>
          <a href="/">
            <Image className={style.logo} src={cleeAILogo} alt="Clee AI logo" />
          </a>
        </div>
      </div>
      <div className={style.right}>
        <div className={style.rightInner}>
          <div
            className={classNames(style.authForm, {
              [style.authFormDisabled]: isLoading,
            })}
          >
            {children}{' '}
          </div>
        </div>
      </div>
    </div>
  );
}
