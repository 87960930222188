import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';
import Link from 'next/link';
import style from './style.module.css';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'submit' | 'button';
  variant?: 'primary' | 'secondary' | 'tertiary';
  isFullWidth?: boolean;
  size?: 'large' | 'small';
  children: string | ReactNode;
  className?: string;
  onClickHandler?: (ev: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  href?: string;
}

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  function Button(
    {
      type = 'submit',
      variant = 'primary',
      isFullWidth = false,
      size = 'large',
      children,
      className = '',
      onClickHandler,
      href = '',
      ...attr
    }: Props,
    ref,
  ) {
    const calculatedClassName = classNames(
      style.button,
      style[variant],
      style[size],
      { [style.fill]: isFullWidth, [className]: Boolean(className) },
    );

    if (Boolean(href)) {
      return (
        <Link
          href={href}
          passHref
          className={calculatedClassName}
          ref={ref as React.Ref<HTMLAnchorElement>}
        >
          {children}
        </Link>
      );
    }

    return (
      <button
        className={calculatedClassName}
        type={type}
        onClick={onClickHandler}
        ref={ref as React.Ref<HTMLButtonElement>}
        {...attr}
      >
        {children}
      </button>
    );
  },
);

export default Button;
